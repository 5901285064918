<template>
 <vs-popup
   class="welcomepage-popup-video"
  :background-color="popupBackgroundColor"
  :title="popupTitle"
  :active.sync="isPopupActiveLocal"
  >
  
    <button @click="closePopup()" style="position: fixed; top: 50px; right: 50px; color:#fff">
      <svg xmlns="http://www.w3.org/2000/svg" class="inline h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <LazyYoutube
        ref="youtubeLazyVideo"
        :src="youtubeLink"
        max-width="720px"
        aspect-ratio="16:9"
        thumbnail-quality="standard"
    />
  </vs-popup>
</template>

<script>

import { LazyYoutube } from "vue-lazytube";

export default {
  props: {
    isPopupActive: {
      type: Boolean,
      required: true
    },
    youtubeLink: {
      required: true
    },
  },
  data () {
    return {
      popupBackgroundColor:"rgba(74,74,74,.8)",
      colorx:'success',
      popupTitle : '',
    }
  },
  watch: {
    async isPopupActive (val) {
      if (!val) return
      this.handleClick('playVideo', 'youtubeLazyVideo')
    }
  },
  computed: {
    isPopupActiveLocal: {
      get () {
        return this.isPopupActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeVideoPopUp')
          this.handleClick('pauseVideo', 'youtubeLazyVideo')
        }
      }
    },

  },
  components: {
    LazyYoutube
  },
  methods: {
    handleClick(event, ref) {
      this.$refs[ref][event]()
    },
    closePopup() {
      this.isPopupActiveLocal = false
    }

  },
  created () {
   
  },
}

</script>

<style>

.welcomepage-popup-video .vs-popup--header{
  display: none !important;
}

.welcomepage-popup-video .vs-popup{
  background: rgb(0 0 0) !important;
}

.welcomepage-popup-video .vs-popup--content{
  margin: 0 !important;
  padding: 0 !important;
}

</style>
